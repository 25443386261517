.self {
    padding: 1.6rem 0 6rem;
}

.navigation {
    display: flex;
    gap: .8rem;
    margin-bottom: 2.4rem;
    flex-wrap: wrap;
}

.navigation button {
    background: #FFFFFF;
    border-radius: 32rem;
    padding: 1rem 2.4rem;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #14161A;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .2s ease-out;
}

.active {
    background-color: #0173FF !important;
    color: #FFFFFF !important;
}

.tests {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4rem;
    margin-bottom: 4.8rem;
}

@media (max-width: 1400px) {

}

@media (max-width: 1200px) {
    .tests {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .tests {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .tests {
        grid-template-columns: 1fr;
    }
}
