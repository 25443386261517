.phone > div {
    background: #F5F5F5;
    border-radius: 1.2rem;
    padding: .75rem 1rem .75rem 4.8rem;
}

.phone > div,
.timer {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 0.6);
}

.timer-container {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

}

.timer {
    padding: 0.5rem 1rem;
    color: black;
}