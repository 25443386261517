.self {
    padding: 1.6rem 0 6rem;
}


.navigation-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.8rem;
    gap: .8rem;
}

.rating {
    padding: 1rem 2.4rem;
    background: rgba(65, 85, 114, 0.1);
    border-radius: 3.2rem;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #263751;
    white-space: nowrap;
    display: inline-block;
}

.rating-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.4rem;
}


.rating-item {
    background: #FFFFFF;
    border-radius: 1.2rem;
    padding: 1.2rem 4rem 1.2rem 1.2rem;
    display: flex;
}

.order {
    font-family: 'SourceSansPro-Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2.5rem;
    text-align: center;
    background: #F5F5F5;
    border-radius: 50%;
    margin-right: 1.2rem;
    height: 5rem;
    width: 5rem;
    color: #121212;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-content p {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #000000;
}


@media (max-width: 992px) {
    .rating-list {
        grid-template-columns: 1fr;
    }

    .navigation-container {
        flex-direction: column;
    }
}