.home-carousel {
    border-radius: 3.2rem;
    overflow: hidden;
    margin-bottom: 2.6rem;
    position: relative;
}

.home-carousel .swiper-pagination {
    bottom: 4rem;
}

.home-carousel .swiper-pagination span {
    width: 1.2rem;
    height: 1.2rem;
    opacity: 1;
    background-color: white;
}

.home-carousel .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #0139FF;
}

.about-us-carousel {
    width: 100%;
    border-radius: 3.2rem;
    overflow: hidden;
}