.payment {
    padding: 2.5rem 4rem;
    background-color: white;
    border-radius: 3.6rem;
    max-width: 95vw;
    max-height: calc(100vh - 15rem);
    overflow-y: auto;
}

.times {
    display: flex;
    justify-content: end;
    padding-top: 2.5rem;
    padding-right: 2rem;
}

.times svg {
    cursor: pointer;
}

.payment-title {
    font-family: "SourceSansPro-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3rem;
    margin-bottom: 2.4rem;
    text-align: center;
    color: #14161A;
    max-width: 45rem;
}

.click {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .8rem;
    margin-bottom: 1.4rem;
}

.click span {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #000000;
}

.label {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #000000;
    margin-bottom: .4rem;
    margin-left: 1rem;
}

.balance {
    padding: 1.2rem;
    gap: 1.2rem;
    background: #F5F5F5;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 1.4rem;
}

.icon {
    padding: .8rem;
    background-color: white;
    border-radius: .8rem;
}

.sum {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 0.8);
}

.buttons {
    display: flex;
    gap: 1.2rem;
    margin-top: 2rem;
}

.buttons button {
    transition: all .2s ease-out;
    padding: 1.2rem 2.4rem;
    background: rgba(65, 85, 114, 0.1);
    border-radius: 6.4rem;
    flex: 1;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #263751;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.buttons .active {
    background-color: #0173FF;
    color: white;
}

.payment-form {
    background: #F5F5F5;
    border-radius: 1.2rem;
    padding: 1.2rem;
    display: flex;
    gap: 1.2rem;
    border: 1px solid transparent;
    position: relative;
    margin-bottom: 3rem;
}

.payment-form input {
    background-color: transparent;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 0.8);
    border: none;
    outline: none;
}

.helper-text {
    position: absolute;
    bottom: -.4rem;
    transform: translateY(100%);
    left: 1rem;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: red;
}

.uzs {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 0.8);
}