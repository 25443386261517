/* Poppins */

@font-face {
    src: url("assets/fonts/Poppins/Poppins-Regular.ttf");
    font-family: "Poppins";
}

@font-face {
    src: url("assets/fonts/Poppins/Poppins-Medium.ttf");
    font-family: "Poppins-Medium";
}

@font-face {
    src: url("assets/fonts/Poppins/Poppins-Bold.ttf");
    font-family: "Poppins-Bold";
}

@font-face {
    src: url("assets/fonts/Poppins/Poppins-ExtraBold.ttf");
    font-family: "Poppins-ExtraBold";
}

@font-face {
    src: url("assets/fonts/Poppins/Poppins-Black.ttf");
    font-family: "Poppins-Black";
}

/* Source Sans Pro */

@font-face {
    src: url("assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf");
    font-family: "SourceSansPro";
}

@font-face {
    src: url("assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf");
    font-family: "SourceSansPro-SemiBold";
}

@font-face {
    src: url("assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf");
    font-family: "SourceSansPro-Bold";
}

@font-face {
    src: url("assets/fonts/SourceSansPro/SourceSansPro-Black.ttf");
    font-family: "SourceSansPro-Black";
}

*,
*:after,
*:before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
}

*::-webkit-scrollbar {
    background-color: #DFDFDF;
    width: .6rem;
    height: .6rem;
}

*::-webkit-scrollbar-thumb {
    border-radius: 1.2rem;
    background-color: rgba(20, 22, 26, 0.2);
}

*::-webkit-scrollbar-button {
    display: none;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    outline: none;
}

#root .app {
    display: flex;
    flex-direction: column;
    background-color: #F6F7F8;
    min-height: 100vh;
}

#root .app > main {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
}

.container {
    width: 125rem;
    margin: 0 auto;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}

/* Alert */

.alert {
    background: #FFFFFF !important;
    box-shadow: 0 .2rem 2rem rgba(0, 0, 0, 0.2) !important;
    border-radius: .4rem !important;
}

.Toastify__toast-container--top-right {
    top: 3rem !important;
    right: 2rem !important;
    width: auto !important;
    max-width: 40rem !important;

}

.alert-inner .Toastify__toast-icon svg, .alert-inner .Toastify__toast-icon {
    width: 2.4rem !important;
    height: 2.4rem !important;
}

.alert-inner .Toastify__toast-icon {
    margin-right: 1.2rem !important;
}

.Toastify__progress-bar--info {
    background: #E1842E !important;
}

.alert-inner > div:nth-child(2) {
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    color: #000000 !important;
    margin-right: 3rem !important;
}

.alert .Toastify__close-button {
    width: 2rem !important;
    height: 2rem !important;
    padding: .4rem !important;
    position: absolute !important;
    right: 1.6rem !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1 !important;
}

.alert .Toastify__close-button svg {
    min-width: 1.6rem !important;
    min-height: 1.6rem !important;
    fill: rgba(0, 0, 0, .3) !important;
}

button {
    -webkit-tap-highlight-color: transparent;
}


@media (max-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}

@media (max-width: 1200px) {
    .container {
        max-width: 960px;
    }
}

@media (max-width: 992px) {
    .container {
        max-width: 720px;
    }

    html {
        font-size: 58%;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 540px;
    }
}

@media (max-width: 576px) {
    .container {
        max-width: 100%;
    }

    html {
        font-size: 55%;
    }
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container--top-right {
        max-width: 100% !important;
        width: auto !important;
        margin: 0 1.5rem !important;
        right: 0 !important;
        top: 1rem !important;
    }
}