.svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25rem;
    margin-left: -40rem;
}

.message-box {
    height: 20rem;
    width: 38rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10rem;
    margin-left: 5rem;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.message-box h1 {
    font-size: 6rem;
    line-height: 4.6rem;
    margin-bottom: 4rem;
}

.buttons-con .action-link-wrap {
    margin-top: 4rem;
}

.buttons-con .action-link-wrap a {
    background: #0173FF;
    padding: .8rem 2.5rem;
    border-radius: .4rem;
    color: #FFF;
    font-weight: bold;
    font-size: 1.4rem;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    margin-right: 1rem;
}

.buttons-con .action-link-wrap a:hover {
    background: #5A5C6C;
    color: #fff;
}

.polygon-1, .polygon-2, .polygon-3, .polygon-4, .polygon-4, .polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
}

.polygon-2 {
    animation-delay: .2s;
}

.polygon-3 {
    animation-delay: .4s;
}

.polygon-4 {
    animation-delay: .6s;
}

.polygon-5 {
    animation-delay: .8s;
}

@keyframes float {
    100% {
        transform: translateY(2rem);
    }
}

@media (max-width: 450px) {
    .svg {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25rem;
        margin-left: -19rem;
    }

    .message-box {
        top: 50%;
        left: 50%;
        margin-top: -10rem;
        margin-left: -19rem;
        text-align: center;
    }
}