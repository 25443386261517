.header-inner {
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-inner > div {
    display: flex;
    gap: 1.2rem;
    align-items: center;
}

.header-inner > div:first-child {
    padding: 0.7rem;
}

nav {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
}

.menu {
    display: flex;
    gap: 0.2rem;
    justify-content: space-between;
    align-items: center;
}

.menu li a {
    font-family: "SourceSansPro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #14161a;
    padding: 0.8rem .8rem;
    cursor: pointer;
    display: inline-block;
    position: relative;
    white-space: nowrap;
}

.menu li a::before {
    content: "";
    position: absolute;
    display: block;
    width: calc(100% - 2rem);
    height: .2rem;
    bottom: .2rem;
    left: 1rem;
    transform: scaleX(0);
    background-color: white;
    transition: transform 0.3s ease;
}

.menu li a:hover::before {
    transform: scaleX(1);
}

.coloredLogo::before {
    background-color: rgba(20, 22, 26, 0.7) !important;
}

.account {
    background-color: rgba(65, 85, 114, 0.1);
    border-radius: 50%;
    min-height: 4.4rem;
    min-width: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease;
    position: relative;
}

.account:hover {
    background-color: #0173FF !important;
}

.account:hover > svg > path {
    stroke: white !important;
}

.account-dropdown {
    position: absolute;
    width: 21rem;
    background: #FFFFFF;
    box-shadow: 0 .2rem 2rem rgba(0, 0, 0, 0.15);
    border-radius: .4rem;
    padding: .8rem 1.6rem;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    top: 110%;
    right: 0;
    gap: .4rem;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease-out;
}

.account-dropdown span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #1D1B1B;
}

.account-dropdown > div,
.account-dropdown > a {
    padding: .8rem .8rem .8rem 0;
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1.2rem;
    width: 100%;
    user-select: none;
}

.active-account {
    opacity: 1;
    visibility: visible;
}

.burger {
    display: none;
    cursor: pointer;
}


.mobileMenu {
    margin-top: 0 !important;
    padding: 3rem 1rem 0;
    transition: all 0.3s ease-out;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 10;
    transform: translateX(100%);
    opacity: 0;
}

.openMobileMenu {
    transform: translateX(0);
    opacity: 1;
    display: block;
}

.mobileMenuBtn {
    cursor: pointer;
    border: none;
    background: none;
    display: none;
    z-index: 11;
}

.mobileMenuIcon {
    width: 3.2rem;
    stroke: #555;
    height: 3.2rem;
    position: absolute;
    top: -4rem;
    right: 2rem;
    cursor: pointer;
}

.mobileMenuBtn .mobileMenuIconDisplayNone {
    display: none;
}

.mobile {
    margin-top: 3.5rem;
}

.mobile li a {
    font-family: "SourceSansPro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #14161a;
    padding: 1.2rem .8rem 1.2rem 0;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    display: block;
}

.burger svg {
    transition: all .1s ease-out;
}

.home-burger svg {
    stroke: white;
}

@media (max-width: 1200px) {
    .menu {
        display: none;
    }

    .burger {
        display: block;
    }
}

@media (max-width: 576px) {
    .header-inner {
        padding: 2rem 1.5rem;
    }

    .header-inner > div:first-child {
        padding-left: 0;
        padding-right: 1rem;
    }
}