.form {
    margin-top: 3rem;
    margin-bottom: 4rem;
    min-width: 30rem;
}

.form > div {
    position: relative;
}

.form > div > .icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: .4rem;
    transform: translateY(-50%);
    padding: .8rem;
    background-color: #FFFFFF;
    border-radius: .8rem;
    cursor: pointer;
}


.form > div input {
    background-color: #F5F5F5;
    border-radius: 1.2rem;
    border: 1px solid transparent;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: rgb(0, 0, 0);
    padding: .75rem 1rem .75rem 4.8rem;
    outline: none;
    width: 100%;
}

.form > div input::placeholder {
    color: rgb(0, 0, 0, .6);
    display: -webkit-box;
    text-align: start;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form > div > input:placeholder-shown {
    display: -webkit-box;
    text-align: start;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form > div .helper-text {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-top: .5rem;
    display: inline-block;
    color: red;
    position: absolute;
    bottom: -.5rem;
    transform: translateY(100%);
    left: 0;
}
