.self {
    margin-bottom: 7.5rem;
}

.self h2 {
    font-family: 'Poppins-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 130%;
    color: #000000;
    margin-bottom: 3.5rem;
}

.channels-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.4rem;
}

.channel {
    padding: 4rem 3rem;
    border-radius: 3.2rem;
    display: flex;
    flex-direction: column;
}

.title {
    font-family: 'Poppins-Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 4.8rem;
    line-height: 130%;
    margin-bottom: 1.2rem;
}

.desc {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-bottom: 1.6rem;
}

.btn {
    border: none;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
}


.wrapper-right {
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    transform: rotate(-10deg);
    background-color: transparent;
}

.wrapper-inner {
    background-color: transparent;
    border: 1px solid #FFFFFF;
    transform: rotate(-8deg);
    border-radius: 50%;
    padding: 2rem 4rem;
    margin: .1rem;
    transition: all .2s ease-out;
}

.btn-label {
    font-family: 'SourceSansPro-Black', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2.0rem;
    color: white;
    background-color: transparent;
    transform: rotate(18deg);
}


.btn:hover .wrapper-inner {
    background-color: white;
}

.btn .wrapper-inner div {
    transition: all .1s ease-out;
}

.btn:hover .wrapper-inner div {
    color: #233F37;
}

@media (max-width: 992px) {
    .channels-container {
        grid-template-columns: 1fr;
    }

    .title {
        font-size: 3.5rem;
    }
}
