.self {
    background: white;
    border-radius: 2.7rem;
    overflow: hidden;
    flex-direction: column;
    display: flex;
}

.header {
    position: relative;
}

.header .free-test {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    border-radius: 2.7rem 0;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: -0.06em;
    min-width: 7rem;
    padding: .5rem 1.6rem;
    text-align: center;
}

.slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.body {
    padding: 1.6rem 2rem;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;
}

.heading {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #000000;
    margin-bottom: .8rem;
}

.title {
    display: flex;
    gap: .4rem;
    flex-direction: column;
}

.title span,
.title del {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #0173FF;
}

.title del {
    color: red;
}

.duration {
    display: flex;
    gap: .8rem;
    align-items: center;
}

.duration span {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: -0.06em;
    color: #000000;
}

.footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.count {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
}

.footer button {
    background: rgba(1, 115, 255, 0.1);
    /*background: #0173FF;*/
    border-radius: 32rem;
    padding: 1rem 2.4rem;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #0173FF;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .2s ease-out;
}

.footer button:hover {
    background: #0173FF;
    color: white;
}