.self {
    border-radius: 4rem;
    min-height: 50rem;
    padding: 2rem 2rem 2rem 5rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    overflow: hidden;
}

.self > div {
    z-index: 2;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1 !important;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title h3 {
    margin-top: 5rem;
    font-family: 'Poppins-Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 110%;
    color: #FFFFFF;
}

.social-networks > div {
    display: inline-block;
    padding: 3rem 3.2rem;
    margin-top: 1.5rem;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(1.2rem);
    border-radius: 2.4rem;
}

.social-networks > div > span {
    display: flex;
    gap: 3rem;
}

.contact-us {
    padding: 3rem 5rem;
    background-color: white;
    display: inline-block;
    float: right;
    border-radius: 2.4rem;
    width: 43rem;
    max-width: 100%;
}

.contact-us__title {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3rem;
    color: #14161A;
    margin-bottom: 3.2rem;
}

.contact-us__form input,
.contact-us__form textarea {
    background-color: #F5F5F5;
    border-radius: 1.2rem;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: rgba(20, 22, 26, 0.6);
    display: block;
    width: 100%;
    padding: 1.2rem 1rem;
    border: 1px solid transparent;
    outline: none;
    margin-top: 3rem;
}

.contact-us__form textarea {
    resize: none;
}

.contact-us__form button {
    padding: 1.6rem 1.2rem;
    background: #0173FF;
    border-radius: 3.2rem;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #FFFFFF;
    margin-top: 3rem;
    border: none;
    outline: none;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.form-container {
    position: relative;
}

.form-container > span {
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% + .5rem));
    margin-top: .5rem;
    display: inline-block;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: red;
}

@media (max-width: 992px) {
    .self {
        grid-template-columns: 1fr;
        background-image: none !important;
        background: transparent;
        padding: 0;
    }

    .title {
        display: none;
    }

    .contact-us {
        width: 100%;
        padding: 3rem 2rem;
    }

    .contact-us__title {
        text-align: center;
    }

    .overlay {
        display: none;
    }
}