.self {
    background-color: white;
    border-radius: 1.2rem;
    overflow: hidden;
    display: inline-block;
}

.slide {
    width: 100%;
    margin-bottom: 1.6rem;
    aspect-ratio: 16/9;
}


.slide img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.desc {
    padding: 0 2.8rem 3.2rem;
}

.title {
    font-family: 'Poppins-Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: #000000;
    margin-bottom: .4rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #14161A;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.school-title {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3rem;
    color: #14161A;
    margin-bottom: 2.4rem;
}


.school-desc {
    font-family: 'Poppins-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 130%;
    color: #14161A;
}
