.self {
    padding: 8rem 6rem 6rem;
    background-color: #FFFFFF;
    box-shadow: -3.9rem 1.7rem 8.4rem rgba(0, 0, 0, 0.15);
    border-radius: 3rem;
    min-height: 80vh;
    max-height: 95vh;
    overflow-y: auto;
    max-width: calc(100vw - 4rem);
    width: 50rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.self > h3 {
    font-family: 'Poppins-Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 130%;
    color: #000000;
    text-align: center;
}

.self > p {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #000000;
    margin-top: .8rem;
    text-align: center;
}

.self > form {
    margin-top: 3rem;
    margin-bottom: 4rem;
}

.self > form > div {
    position: relative;
}

.icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: .4rem;
    transform: translateY(-50%);
    padding: .8rem;
    background-color: #FFFFFF;
    border-radius: .8rem;
    cursor: pointer;
}


.self > form > div input {
    background-color: #F5F5F5;
    border-radius: 1.2rem;
    border: 1px solid transparent;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: rgb(0, 0, 0);
    padding: .75rem 1rem .75rem 4.8rem;
    outline: none;
    width: 100%;
}

.self > form > div input::placeholder {
    color: rgb(0, 0, 0, .6);
    display: -webkit-box;
    text-align: start;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.self > form > div > input:placeholder-shown {
    display: -webkit-box;
    text-align: start;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.helper-text {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-top: .5rem;
    display: inline-block;
    color: red;
    position: absolute;
    bottom: -.5rem;
    transform: translateY(100%);
    left: 0;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    margin-top: auto;
}

.self .buttons button {
    padding: 1.2rem 2.4rem;
    background: #0173FF;
    border-radius: 1.2rem;
    width: 100%;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;

}

.self .buttons .register {
    color: #000000;
    background: #F5F5F5;
}

.eye-icon {
    position: absolute;
    display: inline-block;
    right: 1.2rem;
    top: 50%;
    padding: .8rem;
    border-radius: .8rem;
    cursor: pointer;
    transform: translateY(-50%);
}

.pwd-container > input {
    padding-right: 5rem !important;
}

.reset-password {
    display: inline-block;
    padding: .5rem 1rem;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #0173FF;
    cursor: pointer;
}


@media (max-width: 1400px) {

}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {

}

@media (max-width: 576px) {
    .self {
        padding: 8rem 3rem 6rem;

    }
}