.news-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.4rem;
    margin-bottom: 3rem;
}


@media (max-width: 1400px) {

}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
    .news-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {
    .news-container {
        grid-template-columns: 1fr;
    }
}