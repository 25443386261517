.self {
    padding: 4rem 0;
    grid-template-columns: 35rem 1fr;
    display: grid;
    gap: 2.5rem;
    margin-bottom: 8rem;
    min-height: 100%;
}

.self > div {
    min-height: 100%;
    background-color: white;
    border-radius: 3.2rem;
}


.history {
    padding: 4rem;
}

.table {
    max-height: 60rem;
    overflow: auto;
}

.table table {
    width: 100%;
    border-spacing: 0 .8rem;
}

.table table th,
.table table td {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #000000;
    padding: 2.4rem 0;
    background: #FAFAFA;
    text-align: start;
}

.table table th {
    font-weight: 500 !important;
    font-family: Poppins-Medium, sans-serif !important;
}

.table table th:first-child,
.table table td:first-child {
    -moz-border-radius: 1.2rem 0 0 1.2rem;
    -webkit-border-radius: 1.2rem 0 0 1.2rem;
    padding-left: .8rem;
    min-width: 3rem;
    text-align: center;
}

.table table th:last-child,
.table table td:last-child {
    -moz-border-radius: 0 1.2rem 1.2rem 0;
    -webkit-border-radius: 0 1.2rem 1.2rem 0;
    padding-right: .8rem;
}


.profile {
    overflow: hidden;
}

.photo-container {
    position: relative;
    padding: 3rem .5rem;
    min-height: 25rem;
    /*overflow: hidden;*/
    border-radius: 3.2rem;
}

.back-photo-container,
.back-image {
    width: 40rem;
    height: 40rem;
    position: absolute;
    top: -15rem;
    left: 50%;
    border-radius: 50%;
    overflow: hidden;
    transform: translateX(-50%);
    backdrop-filter: blur(2.2rem);
    mix-blend-mode: multiply;
}

.back-photo-container {
    z-index: 2;
}

.back-image {
    display: inline-block;
    z-index: 1;
}

.back-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.account-photo-container {
    position: absolute;
    top: 3rem;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
}

.photo-input {
    display: none;
}

.photo-input-label {
    cursor: pointer;
    display: block;
    border-radius: 50%;
    width: 10rem;
}

.account-image img {
    width: 100%;
    min-width: 10rem;
    border-radius: 50%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.name {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #000000;
    margin-top: .8rem;
    text-align: center;
}

.rating {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #000000;
    text-align: center;
    margin-top: .8rem;
}

.form {
    margin-top: 3rem;
    padding: 0 1.8rem;
}

.form-item {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    position: relative;
    color: rgba(0, 0, 0, 0.5);
    background: #F9F9F9;
    border-radius: 1.2rem;
    padding: 1.2rem 1.2rem 1.2rem 5rem;
    margin-bottom: .8rem;
    min-height: 5rem;
}

.form-item > span {
    position: absolute;
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: .8rem;
    top: 50%;
    transform: translateY(-50%);
    left: .8rem;
}

.form-item > span path {
    stroke: black;
}

.form button {
    padding: 1.2rem 2.4rem;
    background: #F9F9F9;
    border-radius: 6.4rem;
    outline: none;
    border: none;
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    width: 100%;
    line-height: 2.5rem;
    color: #263751;
    cursor: pointer;
}

@media (max-width: 992px) {
    .self {
        grid-template-columns: 1fr;
    }

    .history {
        padding: 3rem 1.5rem 0;
    }

    .form {
        padding: 0 1.5rem;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {

}