.self {
    background-color: #FFFFFF;
    border-radius: 4rem;
    margin-bottom: 3rem;
    padding: 2rem 2.5rem 3.2rem;
}

.self > div:first-child {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
}

.logo-container {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
}

.logo {
    display: flex;
    gap: .6rem;
    align-items: center;
}

.logo > span {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: rgba(0, 0, 0, 0.6);
}

.social-networks {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.social-networks > div {
    display: flex;
    gap: .8rem;
}

.social-networks a {
    display: inline-block;
    padding: 1rem;
    background-color: #F5F5F5;
    border-radius: 1.2rem;
}

.links h4 {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3rem;
    color: #000000;
    margin-bottom: .7rem;
}

.links ul {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.links ul li a {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 0.8);
    transition: all .3s ease-out;
}

.links ul li {
    transition: all .3s ease-out;
    cursor: pointer;
}

.links ul li:hover {
    margin-left: .5rem;
}

.copyright {
    margin-top: 2.4rem;
    display: flex;
    gap: 4rem;
    justify-content: center;
    align-items: center;
}

.copyright > span,
.developer,
.developer > a {
    font-family: 'SourceSansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: rgba(20, 22, 26, 0.4);
}

.developer > a {
    display: inline-block;
    padding: .5rem;
    font-family: 'SourceSansPro-SemiBold', sans-serif;
    font-weight: 600;
}

@media (max-width: 992px) {
    .self > div:first-child {
        grid-template-columns: repeat(2, 1fr);
    }

    .copyright {
        text-align: center;
    }
}


@media (max-width: 576px) {
    .self {
        margin-bottom: 0;
        border-radius: 0;
    }

    .section {
        background-color: white;
        padding: 0!important;
    }

    .self > div:first-child {
        display: flex;
        flex-direction: column-reverse;
    }

    .logo {
        display: none;
    }

    .copyright {
        align-items: start;
        flex-direction: column;
        gap: .5rem;
        text-align: start;
    }

    .copyright a {
        padding-left: 0;
    }

    .social-networks {
        flex-direction: row;
    }
}
